const Home = () => {
  return (
      <div className="container">
      <div className="header">
        <span style={{ display: 'inline-block', textAlign: 'center' }}>Sorry, the challenge could not be loaded. Please try again later.</span>
      </div>
    </div>
  );
};

export default Home;
